import Filters from '../util/FilterReviewsAccount';

export default {
  init() {
    var input = document.getElementById('search');

    input.addEventListener('keyup', function (event) {
      let key = event.key || event.code;
      if (key === 13 || key === 'Enter') {
        event.preventDefault();
        document.getElementById('s').value = document.getElementById(
          'search'
        ).value;
        document.getElementById('searchform').submit();
      }
    });

    // JavaScript to be fired on all pages
    $('#btn-menu').on('click', () => {
      $('#menu-mobile').fadeIn('100').find('#body-menu-mobile').css('left', 0);
    });

    $('#overlay, #btn-close').on('click', () => {
      $('#body-menu-mobile').css('left', '-100%');
      setTimeout(() => {
        $('#menu-mobile').fadeOut('100');
      }, 300);
    });

    $('.content-inside').mouseout(function () {
      //console.log('mouseout');
      if ($(this).children('.button-container-card').is(':hidden')) {
        $(this).children('.variable').hide();
        //console.log('entroooooo');
      }
    });

    $(document).on('click', '.product_type_simple:not(.vcart)', function (e) {
      e.preventDefault();

      if (
        $(this).parents('.content-inside').children('.variable').is(':hidden')
      ) {
        $(this).parents('.content-inside').children('.variable').show();
      } else {
        $(this).parents('.content-inside').children('.variable').hide();
      }
    });

    $('.li-question').click(function (e) {
      e.preventDefault();

      let height = $(this).find('.answer').prop('scrollHeight');

      if ($(this).find('.answer').hasClass('active')) {
        $(this).find('.answer').removeClass('active');
        $(this).find('.answer').css('height', 0);
      } else {
        $(this).find('.answer').addClass('active');
        $(this)
          .find('.answer')
          .css('height', height + 20);
      }
    });

    //dropdown menu
    jQuery('.anchor-menu').on('click', (e) => {
      let parent = jQuery(e.currentTarget).parents('.main-item');

      if (!parent.hasClass('active')) {
        let scroll = parent.prop('scrollHeight');
        parent.height(scroll);
        parent.addClass('active');
      } else {
        parent.height(40);
        parent.removeClass('active');
      }
    });

    //dropdown pdp
    $('.item-collapse').on('click', (e) => {
      let item = $(e.currentTarget);
      if (!item.hasClass('active')) {
        let scroll = item.prop('scrollHeight');
        item.height(scroll);
        item.addClass('active');
      } else {
        item.height(50);
        item.removeClass('active');
      }
    });

    const nextStep = (container) => {
      if (!container.hasClass('active')) {
        $('.collapse-checkout').not(container).removeClass('active');
        container.addClass('active');

        const body = container.find('.body-collapse');
        $('.body-collapse').not(body).slideUp('fast');
        body.slideDown('slow');
      }
    };

    //dropdown checkout
    $('.next-step').on('click', function (e) {
      e.preventDefault();

      const container = $(this).closest('.collapse-checkout').next();
      nextStep(container);
    });

    $('.item-pay-collapse').on('click', function (e) {
      e.preventDefault();

      const container = $(this).closest('.collapse-checkout');
      nextStep(container);
    });
    $('.item-pay-collapse').eq(0).trigger('click');

    $('.new-carousel-content-inner').slick({
      dots: true,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      adaptiveHeight: true,
      nextArrow: $('.new-carousel-next'),
      prevArrow: $('.new-carousel-prev'),
    });

    $('.slider-testimonials').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    });

    $('.compare-slide-content').slick({
      dots: false,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      arrows: true,
    });

    $('.slider-quick-container').slick({
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    });

    //inicializar slider pdp
    let sliderPdp = () => {
      if (screen.width < 1024) {
        $('.slider-pdp').slick({
          dots: true,
          infinite: true,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
        });
      }
    };

    $(window).resize(() => {
      sliderPdp();
    });

    $(document).ready(() => {
      sliderPdp();
    });

    $('.container-sort, .container-filter').click(function (e) {
      e.preventDefault();

      let height = $(this)
        .siblings('.list-sort,.list-filter')
        .prop('scrollHeight');
      $('.list-sort,.list-filter').css('height', 0);

      if ($(this).siblings('.list-sort,.list-filter').hasClass('active-sort')) {
        $('.list-sort,.list-filter').removeClass('active-sort');
        $(this).siblings('.list-sort,.list-filter').css('height', 0);

        $('.container-sort .fa-angle-right').css('display', 'block');
        $('.container-sort .fa-angle-down').css('display', 'none');
      } else {
        $(this).siblings('.list-sort,.list-filter').addClass('active-sort');
        $(this).siblings('.list-sort,.list-filter').css('height', height);

        $('.container-sort .fa-angle-right').css('display', 'none');
        $('.container-sort .fa-angle-down').css('display', 'block');
      }
    });

    $('.click-filter').click(function (e) {
      e.preventDefault();
      let height = $(this).siblings('.sort').prop('scrollHeight');

      if ($(this).siblings('.sort').hasClass('active')) {
        $(this).siblings('.sort').removeClass('active');
        $(this).siblings('.sort').css('height', 0);
      } else {
        $('.sort').removeClass('active');
        $('.sort').css('height', 0);
        $(this).siblings('.sort').addClass('active');
        $(this)
          .siblings('.sort')
          .css('height', height + 30);
      }
    });

    $(document).on('click', '.order-filter,.order-sort', function (e) {
      e.preventDefault();
      let height = $(this)
        .parent('.list-sort,.list-filter')
        .prop('scrollHeight');
      $('.list-sort,.list-filter').css('height', 0);

      if ($(this).parent('.list-sort,.list-filter').hasClass('active-sort')) {
        $('.list-sort,.list-filter').removeClass('active-sort');
        $(this).parent('.list-sort,.list-filter').css('height', 0);
      } else {
        $(this).parent('.list-sort,.list-filter').addClass('active-sort');
        $(this).parent('.list-sort,.list-filter').css('height', height);
      }
    });
    $('body').on('click', '.options-select-customicer', function (e) {
      e.preventDefault();
      let value = $(this).attr('data-value');
      let text = $(this).text();
      console.log('text', text);

      $('#select-customer,#form_id_client_as_shop').val(value);
      $('#select-customer,#form_id_client_as_shop').trigger('change');
      $('.text-select-check-cart').text(text);
      $('.list-select').removeClass('active-sort');
      $('.list-select').css('height', 0);
    });

    $('body').on('click', '#select-check-cart', function () {
      let container = 'ul.list-select';
      let height = $(this).siblings(container).height();
      console.log('hola');
      if (height === 0) {
        $(this)
          .siblings(container)
          .css('height', height + 140);
        $(this).addClass('active');
      } else {
        $(this).siblings(container).css('height', 0);
        $(this).removeClass('active');
      }
    });

    $('.list-thum-img-slider a').on('click', function (event) {
      if (this.hash !== '') {
        event.preventDefault();
        let src = $(this).find('img').attr('src'),
          title = $(this).find('img').attr('title'),
          data = $(this).find('img').data(),
          srcset = $(this).find('img').attr('srcset');

        let changeSrc = $('.woocommerce-product-gallery__wrapper img');
        let changeHref = $('.woocommerce-product-gallery__wrapper a');

        changeHref.attr('href', data.src);
        changeSrc.attr('src', src);
        changeSrc.attr('title', title);
        changeSrc.attr('data-src', data.src);
        changeSrc.attr('data-large_image', data.large_image);
        changeSrc.attr('srcset', srcset);
      } // End if
    });

    $('body').on('click', '.container-title', function () {
      let height = $(this).siblings('.answer').height();

      if (height === 0) {
        $(this)
          .siblings('.answer')
          .css('height', height + 40);
        $(this).addClass('active');
      } else {
        $(this).siblings('.answer').css('height', 0);
        $(this).removeClass('active');
      }
    });

    $(document).on('keyup', '.__field', function (e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        $('.tablet-cart .coupon #coupon_code').val($(this).val());
        $('.tablet-cart .coupon button').trigger('click');
        $(this).val('');
      } else {
        $('.tablet-cart .coupon #coupon_code').val($(this).val());
      }
    });

    $(document).on('click', '.btn--inside', function () {
      $('.tablet-cart .coupon button').trigger('click');
      $('.__field').val('');
    });

    $('.coupon_checkout input').on('keyup', function (e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        e.preventDefault();

        $('#coupon_code').val($(this).val());
        $('button[name=apply_coupon]').trigger('click');
      } else {
        $('#coupon_code').val($(this).val());
      }
    });

    $('.coupon_checkout .btn').on('click', function (e) {
      e.preventDefault();

      $('button[name=apply_coupon]').trigger('click');
    });

    $(document).on('click', '.btn-variant', function () {
      let value = $(this).data('value');
      $('.variations .value select').val(value);
      $('.variations .value select').trigger('change');
    });

    $('body').on('click', '.new-review', function () {
      let height = $('#reviews').height();

      if (height === 0) {
        $(this).find('.text').text('Cancel');
        $(this).find('.icon').hide();
        $('#reviews').css('height', height + 345);
        $(this).addClass('active');
      } else {
        $(this).find('.text').text('Write a review ');
        $(this).find('.icon').show();
        $('#reviews').css('height', 0);
        $(this).removeClass('active');
      }
    });

    $('.modal-login').click(function (e) {
      e.preventDefault();

      $('.init-login').fadeIn();
    });

    $('.container-login .fa-times').click(function (e) {
      e.preventDefault();

      $('.init-login').fadeOut();
    });

    $('.tab').click(function (e) {
      e.preventDefault();
      let tab = $(this).data('name');

      $('.tab').removeClass('active');
      $(this).addClass('active');

      $('.container-tab').removeClass('active');
      $(tab).addClass('active');
    });

    /*$('.li-account').click(function (e) {
      e.preventDefault();
      let name = $(this).data('name');

      $('.li-account').removeClass('active');
      $(this).addClass('active');

      $('.grid-account').removeClass('active');
      $(name).addClass('active');
    });*/

    /*$('.edit-account').click(function (e) {
      e.preventDefault();

      $('.span-account').each(function() {
        $(this).replaceWith('<input class="input-account" type='+$(this).data('type')+' placeholder='+$(this).data('placeholder')+' value="'+ $(this).text().trim() +'">')
      });

      $('.container-password').css('display', 'none');
      $('.submits-setting').css('display', 'flex');
      $('.container-input').css('color', '#000');

      $(this).css('display', 'none');
    });*/

    $('.edit-password').click(function (e) {
      e.preventDefault();

      $('.password-update').css('display', 'flex');
      $('.container-password, .container-account').css('display', 'none');
      $('.submits-setting').css('display', 'flex');
      $('.container-input').css('color', '#000');

      $(this).css('display', 'none');
    });

    $('.show').click(function (e) {
      e.preventDefault();

      var tipo = document.getElementById('password-login');
      if (tipo.type == 'password') {
        tipo.type = 'text';
      } else {
        tipo.type = 'password';
      }
    });

    $('.ul-faqs .li-faqs:first-child').addClass('active');
    $('.container-faqs:first-child').addClass('active');

    $('.ul-faqs .li-faqs').click(function (e) {
      e.preventDefault();

      $('.ul-faqs .li-faqs').removeClass('active');
      $(this).addClass('active');

      let name = $(this).data('name');

      $('.container-faqs').removeClass('active');
      $(name).addClass('active');
    });

    $('.icon-search')
      .hover(function () {
        $('.session').addClass('active');
      })
      .mouseleave(function () {
        setTimeout(function () {
          if (!$('.search-head').is(':focus')) {
            $('.session').removeClass('active');
          }
        }, 300);
      });

    $('.search-head').focusout(function (e) {
      e.preventDefault();
      if (!$('.icon-search').is(':hover')) {
        setTimeout(function () {
          $('.session').removeClass('active');
        }, 300);
      }
    });

    // $('.a-specify-apply').on('click', function (e) {
    //   e.preventDefault();
    //   const href = $(this).attr('href');
    //   $('html, body').animate({ scrollTop: $(href).offset().top - 50}, 800);
    // });

    window.scrollTo(0, 0);
    const hash = window.location.hash;
    if (hash !== '') {
      if ($(hash).length > 0) {
        document.querySelector(hash).scrollIntoView({
          behavior: 'smooth',
        });
      }
    }

    function clickHandler(e) {
      const hash = e.currentTarget.hash;

      if (hash && $(hash).length > 0) {
        e.preventDefault();
        document.querySelector(hash).scrollIntoView({
          behavior: 'smooth',
        });
      }
    }

    if ($('.nav-item').length > 0) {
      const links = document.querySelectorAll('.nav-item');
      for (let i = 0; i < links.length; i++) {
        links[i].addEventListener('click', clickHandler);
      }
    } else {
      console.log('class no exist');
    }

    function imprSelec() {
      var title_page = document.getElementById('title-post');
      var section_page = document.getElementById('section-post');
      var ventimp = window.open(' ', 'popimpr');
      ventimp.document.write(title_page.innerHTML, section_page.innerHTML);
      ventimp.document.close();
      ventimp.print();
      ventimp.close();
    }

    $('.section-post .actions .action:first-child').click(function (e) {
      e.preventDefault();

      if ($('.share_post').hasClass('active')) {
        $('.share_post').removeClass('active');
      } else {
        $('.share_post').addClass('active');
      }
    });

    $('#download').click(function (e) {
      e.preventDefault();

      $('.section-post .actions').css('display', 'none');
      imprSelec();
      $('.section-post .actions').css('display', 'flex');
    });

    $('.init-login .overlight-login .container-login .gform_footer').append(
      '<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-right fa-w-14 fa-3x"><path fill="currentColor" d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z" class=""></path></svg>'
    );

    function openInitCollapse() {
      const scrollHeight = $('#area-collapse')
        .find('.active .form')
        .prop('scrollHeight');
      $('#area-collapse').find('.active .form').height(scrollHeight);
    }

    $(document).ready(function () {
      if ($('#area-collapse').length) {
        openInitCollapse();
      }

      $('.next-action-collapse').on('click', function () {
        console.log(2);
        $('#area-collapse')
          .find('.container-collapse.active')
          .next('.container-collapse')
          .addClass('active')
          .prev('.container-collapse')
          .removeClass('active')
          .find('.form')
          .height(0);

        setTimeout(() => {
          $('#area-collapse')
            .find('.container-collapse.active')
            .prev('.container-collapse')
            .addClass('backCollapse');
        }, 1000);

        openInitCollapse();
      });

      $(document).on('click', '.backCollapse', function () {
        console.log(1);
        $('#area-collapse')
          .find('.container-collapse.active')
          .prev('.container-collapse')
          .removeClass('backCollapse')
          .addClass('active')
          .next('.container-collapse')
          .removeClass('active')
          .find('.form')
          .height(0);

        openInitCollapse();
      });
    });
  },
  finalize() {
    //init filter reviews account
    new Filters();
    // JavaScript to be fired on all pages, after page specific JS is fired

    initStarts('ratings');

    filterClick();

    filterActions();
  },
};
/* init starts */
function initStarts(id) {
  $('body')
    // Tabs
    .on('init', '.wc-tabs-wrapper, .woocommerce-tabs', function () {
      $(this)
        .find('.wc-tab, .woocommerce-tabs .panel:not(.panel .panel)')
        .hide();

      var hash = window.location.hash;
      var url = window.location.href;
      var $tabs = $(this).find('.wc-tabs, ul.tabs').first();

      if (
        hash.toLowerCase().indexOf('comment-') >= 0 ||
        hash === '#reviews' ||
        hash === '#tab-reviews'
      ) {
        $tabs.find('li.reviews_tab a').click();
      } else if (
        url.indexOf('comment-page-') > 0 ||
        url.indexOf('cpage=') > 0
      ) {
        $tabs.find('li.reviews_tab a').click();
      } else if (hash === '#tab-additional_information') {
        $tabs.find('li.additional_information_tab a').click();
      } else {
        $tabs.find('li:first a').click();
      }
    })
    .on('click', '.wc-tabs li a, ul.tabs li a', function (e) {
      e.preventDefault();
      var $tab = $(this);
      var $tabs_wrapper = $tab.closest('.wc-tabs-wrapper, .woocommerce-tabs');
      var $tabs = $tabs_wrapper.find('.wc-tabs, ul.tabs');

      $tabs.find('li').removeClass('active');
      $tabs_wrapper.find('.wc-tab, .panel:not(.panel .panel)').hide();

      $tab.closest('li').addClass('active');
      $tabs_wrapper.find($tab.attr('href')).show();
    })
    // Review link
    .on('click', 'a.woocommerce-review-link', function () {
      $('.reviews_tab a').click();
      return true;
    })
    // Star ratings for comments
    .on('init', `#${id}`, function () {
      $(`#${id}`).hide().before(
        `<p class="${id} stars">\
						<span>\
							<a class="star-1" href="#">1</a>\
							<a class="star-2" href="#">2</a>\
							<a class="star-3" href="#">3</a>\
							<a class="star-4" href="#">4</a>\
							<a class="star-5" href="#">5</a>\
						</span>\
					</p>`
      );
    })
    .on('click', `#respond p.${id} a`, function () {
      var $star = $(this),
        $newrating = $(this).closest('#respond').find(`#${id}`),
        $container = $(this).closest(`.${id}`);

      $newrating.val($star.text());
      $star.siblings('a').removeClass('active');
      $star.addClass('active');
      $container.addClass('selected');

      return false;
    })
    .on('click', '#respond #submit', function () {
      var $newrating = $(this).closest('#respond').find(`#${id}`),
        newrating = $newrating.val(),
        wc_single_product_params = '';

      if (
        $newrating.length > 0 &&
        !newrating &&
        wc_single_product_params.review_newrating_required === 'yes'
      ) {
        window.alert(wc_single_product_params.i18n_required_newrating_text);

        return false;
      }
    });
  // Init Tabs and Star newratings
  $(`.wc-tabs-wrapper, .woocommerce-tabs, #${id}`).trigger('init');
}

function filterClick() {
  $(window).on('load', function () {
    $(document).on('click', '.click-filter:not(.active)', function () {
      var self = $(this),
        id = self.attr('id');

      self.addClass('active');
      $('ul.sort.filter-content[target="' + id + '"]').css('height', '220px');
    });

    $(document).on('click', '.click-filter.active', function () {
      var self = $(this),
        id = self.attr('id');

      self.removeClass('active');
      $('ul.sort.filter-content[target="' + id + '"]').css('height', '0px');
    });
  });
}

function filterActions() {
  $(window).on('load', function () {
    $(document).on('change', '#order_date', function () {
      var datevalue = $(this).val(),
        status = $('select#sStatus').val() ? $('select#sStatus').val() : '',
        comm_date = $('input.orcomm').val(),
        url = '';

      $('input.ordate').val(datevalue).attr('value', datevalue);

      console.log(datevalue);
      if (datevalue == '' && status != '' && comm_date == '') {
        url = '?sStatus=' + status;
      } else if (datevalue && status && comm_date == '') {
        url = '?sStatus=' + status + '&order_date=' + datevalue;
      } else if (datevalue != '' && status != '' && comm_date != '') {
        url =
          '?sStatus=' +
          status +
          '&order_date=' +
          datevalue +
          '&commission_date=' +
          comm_date;
      } else if (datevalue == '' && status != '' && comm_date != '') {
        url = '?sStatus=' + status + '&commission_date=' + comm_date;
      } else if (datevalue == '' && status == '' && comm_date != '') {
        url = '?commission_date=' + comm_date;
      } else if (datevalue != '' && status == '' && comm_date != '') {
        url = '?order_date=' + datevalue + '&commission_date=' + comm_date;
      } else if (datevalue != '' && status == '' && comm_date == '') {
        url = '?order_date=' + datevalue;
      }

      console.log(url);

      $('input.urlfilter').attr('value', url).val(url);

      //if(url) {
      $('span.filt[disabled]').removeAttr('disabled');
      //}
    });

    $(document).on('change', '#commission_order_date', function () {
      var datevalue = $('input.ordate').attr('value'),
        status = $('select#sStatus').val() ? $('select#sStatus').val() : '',
        comm_date = $(this).val(),
        url = '';

      $('input.orcomm').val(comm_date).attr('value', comm_date);

      if (datevalue == '' && status != '' && comm_date == '') {
        url = '?sStatus=' + status;
      } else if (datevalue && status && comm_date == '') {
        url = '?sStatus=' + status + '&order_date=' + datevalue;
      } else if (datevalue != '' && status != '' && comm_date != '') {
        url =
          '?sStatus=' +
          status +
          '&order_date=' +
          datevalue +
          '&commission_date=' +
          comm_date;
      } else if (datevalue == '' && status != '' && comm_date != '') {
        url = '?sStatus=' + status + '&commission_date=' + comm_date;
      } else if (datevalue == '' && status == '' && comm_date != '') {
        url = '?commission_date=' + comm_date;
      } else if (datevalue != '' && status == '' && comm_date != '') {
        url = '?order_date=' + datevalue + '&commission_date=' + comm_date;
      } else if (datevalue != '' && status == '' && comm_date == '') {
        url = '?order_date=' + datevalue;
      }

      console.log(url);

      $('input.urlfilter').attr('value', url).val(url);

      //if(url) {
      $('span.filt[disabled]').removeAttr('disabled');
      //}
    });

    $(document).on('click', '.status-filter', function (e) {
      e.preventDefault();
      var datevalue = $('input.ordate').val(),
        status = $(this).attr('status'),
        comm_date = $('input.orcomm').val(),
        url = '';

      $('select#sStatus option').removeAttr('selected');
      $('select#sStatus option[value="' + status + '"]').attr('selected');

      if ($('#order_date').val()) {
        datevalue = $('#order_date').val();
        console.log('si:' + datevalue);
      }

      //console.log(status)
      if (datevalue == '' && status != '' && comm_date == '') {
        url = '?sStatus=' + status;
      } else if (datevalue != '' && status && comm_date == '') {
        url = '?sStatus=' + status + '&order_date=' + datevalue;
      } else if (datevalue != '' && status != '' && comm_date != '') {
        url =
          '?sStatus=' +
          status +
          '&order_date=' +
          datevalue +
          '&commission_date=' +
          comm_date;
      } else if (datevalue == '' && status != '' && comm_date != '') {
        url = '?sStatus=' + status + '&commission_date=' + comm_date;
      } else if (datevalue == '' && status == '' && comm_date != '') {
        url = '?commission_date=' + comm_date;
      } else if (datevalue != '' && status == '' && comm_date != '') {
        url = '?order_date=' + datevalue + '&commission_date=' + comm_date;
      } else if (datevalue != '' && status == '' && comm_date == '') {
        url = '?order_date=' + datevalue;
      }

      console.log('theurl: ' + url);

      $('input.urlfilter').val(url);

      //if(url) {
      $('span.filt[disabled]').removeAttr('disabled');
      //}
    });

    $(document).on(
      'click',
      'li.filter-sort.btns-filter span.btn-filter',
      function () {
        var self = $(this),
          status = self.attr('status'),
          value = $('input.ordate').val(),
          comm_date = $('input.orcomm').val(),
          url = '';

        $('li.filter-sort.btns-filter span.btn-filter').removeClass('active');
        self.addClass('active');

        $('select#sStatus option').removeAttr('selected');
        $('select#sStatus option[value="' + status + '"]').attr(
          'selected',
          true
        );

        $('select#sStatus').val(status);

        if ($('#order_date').val() != '') {
          value = $('#order_date').val();
        }

        //console.log(status)
        if (value == '' && status != '' && comm_date == '') {
          url = '?sStatus=' + status;
        } else if (value && status && comm_date == '') {
          url = '?sStatus=' + status + '&order_date=' + value;
        } else if (value != '' && status != '' && comm_date != '') {
          url =
            '?sStatus=' +
            status +
            '&order_date=' +
            value +
            '&commission_date=' +
            comm_date;
        } else if (value == '' && status != '' && comm_date != '') {
          url = '?sStatus=' + status + '&commission_date=' + comm_date;
        } else if (value == '' && status == '' && comm_date != '') {
          url = '?commission_date=' + comm_date;
        } else if (value != '' && status == '' && comm_date != '') {
          url = '?order_date=' + value + '&commission_date=' + comm_date;
        } else if (value != '' && status == '' && comm_date == '') {
          url = '?order_date=' + value;
        }

        console.log(url);

        $('input.urlfilter').val(url);

        //if(url) {
        $('span.filt[disabled]').removeAttr('disabled');
        //}
      }
    );

    $(document).on(
      'click',
      '.grid-account.commissions  span.filt',
      function (e) {
        //var url = $('input.urlfilter').val()
        /*$.ajax({
        type: 'GET',
        url: url,
        beforeSend: function() {
            $('.order-commissions-table-fn tbody').remove()
            $('.commissions-paginate').remove()
            $('.click-filter.active').removeClass('active')
            $('.filter ul.active').removeClass('active')
            $('ul.sort').css('height', '0px')
            $('.loading-msg').fadeIn()
        },
        success: function(data) {
            var finder = $(data).find('.order-commissions-table-fn tbody.body-tablet'),
            bodytoappend = ''
            //$('.order-commissions-table-fn').append(data)
            $('.loading-msg').fadeOut()
            if(finder.length > 0) {
                var $text = ' items found',
                count = 0
                finder.each(function(k,v) {
                  console.log(v)
                  bodytoappend = v
                })
                $('.order-commissions-table-fn').append(bodytoappend)

                setTimeout(function() {
                  $('body .commissions .order-commissions-table-fn tbody.body-tablet tr.tr-body').each(function() {
                    count++
                  })

                  if(count == 1) {
                    $text = ' item found'
                  }
                  $('.msg').html(count + $text)
                }, 500)
            } else if(finder.length < 1) {
                $('.msg').html('Not found')
            }
        },
      })*/
        e.preventDefault();
        var url = $('input.urlfilter').val(),
          currenturl = $('input.currenturl_fil').val();

        window.location.href = currenturl + url;
      }
    );

    $(document).on(
      'click',
      '.grid-account.scommissions span.filt',
      function (e) {
        e.preventDefault();
        var url = $('input.urlfilter').val(),
          currenturl = $('input.currenturl_fil').val();

        window.location.href = currenturl + url;
      }
    );

    $(document).on(
      'click',
      '.grid-account.commissions li.filter-sort.btns-filter span.clear',
      function () {
        window.location.href = $('input.currenturl_fil').val();
      }
    );

    $(document).on(
      'click',
      '.grid-account.scommissions li.filter-sort.btns-filter span.clear',
      function () {
        window.location.href = $('input.currenturl_fil').val();
      }
    );

    $(document).on(
      'click',
      '.grid-account.commissions [target="sort"] li a',
      function (e) {
        e.preventDefault();
        var self = $(this),
          value = self.attr('href'),
          //type = self.attr('by'),
          status = self.attr('status') ? self.attr('status') : 'paid',
          url = $('input.urlfilter').val(),
          currenturl = $('input.currenturl_fil').val(),
          query = ''; //(url ? '&order='+value+'&orderby='+type+'&sStatus='+status : '?order='+value+'&orderby='+type+'&sStatus='+status)

        if (!self.attr('status')) {
          query = url ? url + '&order=' + value : url + '?order=' + value;
        } else {
          query = '?order=' + value + '&sStatus=' + status;
        }

        console.log(query);

        window.location.href = currenturl + query;
      }
    );

    $(document).on(
      'click',
      '.grid-account.scommissions [target="sort"] li a',
      function (e) {
        e.preventDefault();
        var self = $(this),
          value = self.attr('href'),
          //type = self.attr('by'),
          status = self.attr('status') ? self.attr('status') : 'paid',
          url = $('input.urlfilter').val(),
          currenturl = $('input.currenturl_fil').val(),
          query = ''; //(url ? '&order='+value+'&orderby='+type+'&sStatus='+status : '?order='+value+'&orderby='+type+'&sStatus='+status)

        if (!self.attr('status')) {
          query = url ? url + '&order=' + value : url + '?order=' + value;
        } else {
          query = '?order=' + value + '&sStatus=' + status;
        }

        console.log(query);

        window.location.href = currenturl + query;
      }
    );

    //test 2
    $(document).on('click','.consignment-shipping-address .checkout-consignment a.inv-history', function (e) {
        e.preventDefault();
        var self = $(this),
          name = self.attr('name');

        if (name) {
          $.ajax({
            type: 'POST',
            url: smg_global.ajaxurl,
            data: {
              action: 'smg_global_action',
              process: 'set_inventory_consignment',
              name: name,
            },
            success: function (data) {
              if (data) {
                $(
                  '.consignment-shipping-address .modal .modal-content .container-items .accordion'
                ).html('');
                $(
                  '.consignment-shipping-address .modal .modal-content .container-items .accordion'
                ).append(`${data}`);
              }
            },
            error: function (error) {
              console.log(error);
            },
          });

          $('.modal .modal-content .ckeckout').attr('name', name);
        }
      }
    );

    $(document).on('click', '.modal .modal-content .ckeckout', function (e) {
      e.preventDefault();
      var self = $(this),
        name = self.attr('name');
      if (name) {
        if (name) {
          $.ajax({
            type: 'POST',
            url: smg_global.ajaxurl,
            data: {
              action: 'smg_global_action',
              process: 'set_shipping_address',
              name: name,
            },
            success: function (data) {
              console.log(data);
              if (data) {
                self.css('opacity', '0.3');
                self.css('pointer-events', 'none');
                window.location.href = smg_global.checkout_url;
              }
            },
            error: function (error) {
              console.log(error);
            },
          });
        }
      }
    });

    const btn = document.querySelectorAll('.inv-history');
    const modal = document.querySelector('.modal');
    //const iconClose = document.querySelector('.icon-close');

    for (var i = 0; i < btn.length; i++) {
      btn[i].addEventListener('click', function (e) {
        console.log('clcik');
        e.preventDefault();
        setTimeout(() => {
          modal.style.display = 'flex';
        }, 500);
      });
    }

    const accordion = document.getElementsByClassName('title');
    for (let i = 0; i < accordion.length; i++) {
      accordion[i].addEventListener('click', function () {
        this.classList.toggle('active');
      });
    }

    
  });
}
