export default class {
    constructor() {
      this.grid = $('.reviews-account');
      this.action = 'search_reviews_account';
      this.containers = $('.reviews-account');
  
      /* Hack for bind this */
      const that = this;
  
      $(document).on('click', '.button-page', function (e) {
        e.preventDefault;
        let paged = parseInt($(this).val());
        that.toRenderMore(paged);
      });
    }
  
    toRenderMore(paged) {
      const urlParams = this.getUrlParams();
  
      let filters = this.filters(urlParams);
  
      this.ajax(paged, this.action, filters);
    }
    getUrlParams(URLSearch = null) {
      return new URLSearchParams(URLSearch || window.location.search);
    }
  
    updateParameters(filter_key, filter_val) {
      const urlParams = this.getUrlParams();
  
      if (filter_val.length === 0) urlParams.delete(filter_key);
      else urlParams.set(filter_key, filter_val);
  
      history.pushState(
        {},
        '',
        `${location.pathname}${
          urlParams.toString().length > 0 ? `?${urlParams}` : ''
        }`
      );
    }
    filters(urlParams) {
      let filters = {};
      urlParams.forEach((value, key) => {
        filters[key] = value;
      });
      return filters;
    }
    ajax(paged, action, filters) {
      $.ajax({
        url: '/wp-admin/admin-ajax.php',
        method: 'POST',
        data: {
          paged,
          action,
          filters: JSON.stringify(filters),
        },
        beforeSend: () => {
          this.containers.animate({ opacity: '0.3' });
        },
      })
        .done((response) => {
          this.validate(response);
        })
        .always(() => {
          this.containers.animate({ opacity: '1' });
        });
    }
    validate(response) {
      this.grid.html(response);
    }
    onChange(that) {
      const key = $(that).attr('name');
      const value = $(that).val();
  
      this.updateParameters(key, value);
      this.request();
    }
  }
  